.playerPiece {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  box-sizing: border-box;
  position: absolute;
  height: 1em;
  width: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in;
  transition-property: left, top;
  /* border: 3px solid #1b476a; */
  /* background-color: #1d6299; */
}

.playerPiece_player1 {
  /* border-color: #aa2a16; */
  /* background-color: #cb442e; */
}

.playerPiece_activeShooter {
  border: 2px solid greenyellow;
  border-radius: 4px;
}

.playerPiece_activeTarget {
  border: 2px solid darkred;
  border-radius: 4px;
}

.playerPiece_effect-camouflage_vest {
  opacity: 0.5;
}

.playerPiece span {
  display: block;
  font-size: 0.25em;
}

.playerPiece_highlighted {
  /* border: 3px solid white; */
}

.playerPiece_player1.playerPiece_activePlayer .health {
  box-shadow: 0 0 5px 5px #37b03b;
}

.playerPiece_player2 {
}

.playerPiece_player2.playerPiece_activePlayer .health {
  box-shadow: 0 0 5px 2px #ff4646;
}

.playerPieceAp,
.playerPieceMp {
  font-size: 0.3em;
  position: absolute;
  height: 1em;
  width: 1em;
  line-height: 1em;
  background: white;
  color: black;
  bottom: calc(-0.1em / 0.3);
  text-align: center;
  border-radius: 1em;
  padding: 0.05em;
}

.playerPieceAp {
  left: calc(-0.1em / 0.3);
}

.playerPieceMp {
  right: calc(-0.1em / 0.3);
}

.health {
  display: flex;
  flex-direction: column-reverse;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #323b2e;
  padding: calc(1 * var(--pixel));
}

.healthSegment {
  height: calc(1 * var(--pixel));
  width: calc(1 * var(--pixel));
}

.healthSegment_full {
  background: #4ea91c;
}

.actionPoints {
  position: absolute;
  left: 3px;
  right: 0;
  bottom: 9px;
  display: flex;
  gap: 2px;
  display: none;
}

.actionPointsSegment {
  height: calc(2 * var(--pixel));
  width: calc(2 * var(--pixel));
  background: #ffe100;
  border-radius: calc(2 * var(--pixel));
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.5);
}

.stackedItems {
  position: absolute;
  top: -0.5rem;
  left: -0.75rem;
}

.stackedItems > * {
  display: block;
  width: 2rem;
}

.playerPiece:after {
  display: block;
  content: "";
  width: calc(var(--pixel) * 12);
  height: calc(var(--pixel) * 20);
  background-size: 400%;
  background-position: 0 0;
  background-repeat: no-repeat;
  image-rendering: pixelated;
  position: relative;
  left: calc(var(--pixel) * 1);
  top: calc(var(--pixel) * -2);
}

.playerPiece_type-rambo-girl:after {
  /* background-image: url('images/bush-whacker.png'); */
}

.playerPiece_type-medic:after {
  /* background-image: url('images/medic.png'); */
}

/* All player pieces */

.playerPiece:after {
  --piece-sprite-base-x: 0;
  --piece-sprite-base-y: 0;
  --piece-sprite-idle1-y: calc(var(--pixel) * -21);
  background-position: var(--piece-sprite-base-x) var(--piece-sprite-base-y);
}

.playerPiece_highlighted:after {
  --piece-sprite-base-x: calc(var(--pixel) * -12);
  --piece-sprite-base-y: 0;
  animation: pieceSpriteIdle 1s step-end infinite;
}

.playerPiece_swamp1:after {
  --piece-sprite-base-y: calc(var(--pixel) * -42);
  --piece-sprite-idle1-y: calc(var(--pixel) * -63);
  animation-duration: 1.33s;
}

.playerPiece_swamp2:after {
  --piece-sprite-base-y: calc(var(--pixel) * -84);
  --piece-sprite-idle1-y: calc(var(--pixel) * -105);
  animation-duration: 1.66s;
}

.playerPiece_inRange:after {
  --piece-sprite-base-x: calc(var(--pixel) * -24);
  animation: blink 1s infinite;
}

.playerPiece_effect-stun-grenade:after {
  --piece-sprite-base-x: calc(var(--pixel) * -36);
}

/* Ranger Player 1 */

.playerPiece_player1.playerPiece_type-ranger:after {
  background-image: url("./images/player-green-ranger.png");
}

/* Ranger Player 2 */
.playerPiece_player2.playerPiece_type-ranger:after {
  background-image: url("./images/player-red-ranger.png");
}

/* Rocket Lady*/

.playerPiece_player1.playerPiece_type-rocket-lady:after {
  background-image: url("./images/player-green-rocket-lady.png");
}

.playerPiece_player2.playerPiece_type-rocket-lady:after {
  background-image: url("./images/player-red-rocket-lady.png");
}

/* Sniper */

.playerPiece_player1.playerPiece_type-sniper:after {
  background-image: url("./images/player-green-sniper.png");
}

.playerPiece_player2.playerPiece_type-sniper:after {
  background-image: url("./images/player-red-sniper.png");
}

/* .playerPiece_effect-stun-grenade:before {
  content: "zZz";
  font-size: 0.5em;
  top: -0.2em;
  right: 0;
  font-weight: bold;
  position: absolute;
  color: #000;
} */

/* When the piece is moved to the reachable mouse cell it should 
be on top of everything but have no mouse events */
.playerPiece_hoverState {
  z-index: 2;
  pointer-events: none;
}

/* Blink animation */
@keyframes blink {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

/* Animation: Piece sprite idle */
@keyframes pieceSpriteIdle {
  0% {
    background-position-y: var(--piece-sprite-idle1-y);
  }
  50% {
    background-position-y: var(--piece-sprite-base-y);
  }
  100% {
    background-position-y: var(--piece-sprite-idle1-y);
  }
}
