.game {
  padding: 1rem;
  color: white;
  background-color: #40221f;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  box-sizing: border-box;
}

.boardAndSidebar {
  display: flex;
  align-items: flex-start;
  flex-grow: 0;
  margin: 0 auto;
}

.sidebar {
  padding: 0 1rem;
}

.playerHudTitle {
  margin-top: 0;
}

.controls {
}

.endTurnButton,
.enemyTurnButton {
  border: 0;
  padding: 0.75em 1em;
  background: #4f8334;
  box-shadow: 0 0.25em 0 #304d2e;
  color: white;
  font-weight: bold;
  border-radius: 0.5em;
  font-size: 1em;
  text-transform: uppercase;
}

.endTurnButton {
  cursor: pointer;
}

.enemyTurnButton {
  background: #727272;
  color: black;

  box-shadow: 0 0.25em 0 #515151;
}

.activeEffects {
  margin-top: 1rem;
}

.movementControls {
  text-align: center;
  line-height: 0;
}

.movementControlsN {
}

.movementControlsE {
  margin-left: 1rem;
}

.movementControlsS {
}

.movementControlsW {
}

.gameState {
}

.hand {
  margin-top: 1rem;
  flex-shrink: 0;
  overflow: auto;
  width: calc((20rem * 42) / 16);
}

.pieceInfo {
  border-radius: 0.5rem;
  margin-top: 1rem;
  padding: 0.5rem;
  background: #603e21;
  color: #fff;
}

.pieceInfo_active {
  background: #735c3b;
}

.pieceInfoTitle {
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: bold;
}

.pieceInfoLabel {
  display: inline-block;
  width: 9rem;
  opacity: 0.75;
  font-size: 0.875rem;
}

.pieceInfoRow {
  margin-top: 0.25rem;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
}

.buttonAction {
  background: #352b2e;
  box-shadow: 0 0.25em 0 #4d3e42;
  border: 0;
  border-radius: 0.2rem;
  margin-right: 0.25rem;
  color: rgb(199, 199, 199);
  cursor: pointer;
  margin-top: 0.5rem;
  padding: 0.25rem 0.5rem;
}

.buttonAction:disabled {
  opacity: 0.25;
}

.statusBar {
  margin-top: 0.5rem;
}

.gameError,
.serverError {
  color: #f47c7c;
}

.playedCards {
  display: flex;
  align-items: flex-end;
}

.playedCardsCards {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.playedCardWrapper {
  height: 1.5em;
  width: 1.1em;
  position: relative;
}

.playedCardWrapper .playedCard {
  position: absolute;
  bottom: 0;
  left: 0;
}

.playedCardWrapper:hover {
  background: rgb(242, 213, 173);
}

.playedCardWrapper:hover .playedCard {
  font-size: 1em;
  bottom: 1.5em;
  left: 0;
  transition: all 0.2s ease-in-out;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
}

.playedCard {
  font-size: 0.1em;
  animation-name: playCard;
  animation-duration: 3s;
  z-index: 2;
  /*height: 20em;
    width: 16em;
    margin-left: .25rem;
    background: white;
    border-radius: .2rem;
    overflow: hidden;
    white-space: nowrap;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;*/
}

.playedCard span {
  display: block;
  font-size: 2em;
  font-weight: bold;
}

@keyframes playCard {
  0% {
    font-size: 1em;
  }

  50% {
    font-size: 1em;
  }

  100% {
    font-size: 0.1em;
  }
}

.card {
  margin: 0 1em 1em 0;
}

.overlayContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.winOverlay,
.looseOverlay {
  min-height: 10rem;
  max-width: 50rem;
  background: #77bc1e;
  border: solid 2px #8ad030;
  text-align: center;
  font-size: 2em;
  padding-top: 3.2rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.55);
}

.selectCharacterOverlay {
  pointer-events: all;
  min-height: 10rem;
  max-width: 50rem;
  background: #499dc4;
  border: solid 2px #317a98;
  padding: 1em;
  text-align: center;
  font-size: 2em;
  display: flex;
  gap: 1em;
  box-sizing: border-box;
  border-radius: 0.5rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.55);
}

.selectCharacterOverlay button {
  padding: 0.5rem;
  height: 5rem;
  width: 5rem;
  cursor: pointer;
}

.winOverlay {
  padding: 0 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.looseOverlay {
  pointer-events: all;
  padding-top: 1rem;
  background: #d03310;
  border: solid 2px #ef5e3d;
  overflow: hidden;
}

.looseOverlay img {
  margin-top: 1.2rem;
  display: block;
  width: 100%;
}

.health {
  margin-top: 0.5rem;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  left: 0;
  right: 0;
  bottom: 0;
  background: none;
  gap: 0.25rem;
}

.healthSegment {
  aspect-ratio: 1;
}

.healthSegment_full {
  background: #4f8334;
}
