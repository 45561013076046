body {
  background: #054444;
}

.site {
  padding: 1rem;
}

.siteTitle {
  color: white;
}

.tables {
  display: flex;
  gap: 1rem;
}

.table {
  background: #48abb5;
  width: 10rem;
  border-radius: 0.5rem;
  padding: 1rem;
  text-align: center;
  box-shadow: 0 0.25rem 0 rgba(0, 0, 0, 0.4);
}

.tableName {
  margin: 0;
  color: #010809;
  font-size: 1rem;
}

.tableLogo {
  width: 5rem;
  height: 5rem;
  margin-top: 1rem;
}

.countPlayers {
  color: #15484d;
  margin-top: 0.1rem;
  font-size: 0.875rem;
}

.tableJoinLink {
  display: inline-block;
  padding: 0.5rem;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  width: 100%;
  box-sizing: border-box;
  font-weight: bold;
  color: #594b00;
  background: yellow;
  text-decoration: none;
  text-transform: uppercase;
  box-shadow: 0 0.25rem 0 rgba(0, 0, 0, 0.4);
  border-radius: 0.5rem;
}

.createTable {
  margin-top: 2rem;
  padding: 1rem;
  box-sizing: border-box;
  width: 30rem;
  max-width: 100%;
  border-radius: 0.5rem;
  border: 0.3rem dashed rgba(255, 255, 255, 0.2);
}

.createTableTextInput {
  display: block;
  font-size: 1rem;
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  background: #0e5757;
  border-radius: 0.25rem;
  color: white;
  border: 2px solid #196868;
}

.createTableTextInput:active {
  background: #0e5757;
}

.createTableButton {
  border: 0;
  font-size: 0.875rem;
  display: inline-block;
  padding: 0.5rem;
  margin-top: 0.5rem;
  box-sizing: border-box;
  font-weight: bold;
  color: white;
  background: #0e5757;
  text-decoration: none;
  text-transform: uppercase;
  box-shadow: 0 0.25rem 0 rgba(0, 0, 0, 0.4);
  border-radius: 0.5rem;
  cursor: pointer;
}
