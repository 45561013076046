.board {
  position: relative;
  font-size: calc(16 * var(--pixel));
  border: calc(1 * var(--pixel)) solid #2a4328;
  flex-shrink: 0;
  flex-grow: 0;
  width: 20em;
  height: 16em;
  background-image: url(./images/map.png);
  image-rendering: pixelated;
  background-size: contain;
  z-index: 0;
}

.layer {
  position: absolute;
  pointer-events: none;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.layer > * {
  pointer-events: auto;
}

.layer_mouseCatcher {
  cursor: crosshair;
}

.bush {
  color: black;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  height: 1em;
  width: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.bush {
  color: black;
  image-rendering: pixelated;
  background-image: url(./images/item-tree.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  height: calc(27 * var(--pixel));
  width: calc(21 * var(--pixel));
  transform: translate(calc(-3 * var(--pixel)), calc(-7 * var(--pixel)));
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.mine {
  color: black;
  image-rendering: pixelated;
  background-image: url(./images/item-flag.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  pointer-events: none;
  overflow: hidden;
  text-indent: -999px;
}

.bushDiagonal {
  margin: -0.1em 0 0 -0.1em;
  height: 2.2em;
  width: 2.2em;
}

.bushDiagonal2 {
  margin: -1.1em 0 0 -0.1em;
  height: 2.2em;
  width: 2.2em;
}

.bushHorizontal {
  margin: -0.05em 0 0 -0.05em;
  height: 1.1em;
  width: 2.2em;
}

.bushL1 {
  margin-left: -1em;
  height: 2.1em;
  width: 2.1em;
}

.bushL2 {
  height: 2.1em;
  width: 2.1em;
  margin: -0.1em;
}

.bush1 {
  height: 1.2em;
  width: 1.2em;
  margin: -0.1em;
}

.bushL2 {
}

.overlayCell {
  background: red;
  height: 1em;
  width: 1em;
  position: absolute;
  pointer-events: none;
}

.cell {
  height: 1em;
  width: 1em;
  display: block;
  position: absolute;
}

.cellReachable {
  position: absolute;
  /* a radial gradient that makes a .2em dot in the center */
  background: radial-gradient(
    circle at center,
    rgba(255, 221, 43) 0,
    rgba(255, 221, 43) 0.1em,
    rgba(255, 255, 255, 0) 0.1em
  );
  height: 1em;
  width: 1em;
}

.oddCell {
  z-index: 0;
  background: rgba(0, 0, 0, 0.1);
}

.cellSwampDeep,
.cellSwamp {
  position: absolute;
  background: #4c6505;
  opacity: 0.4;
  height: 1em;
  width: 1em;
}

.cellSwampDeep {
  opacity: 0.8;
}

.cellSwampBridge {
  position: absolute;
  background: #3f3f3f;
  height: 1em;
  width: 1em;
  opacity: 0.8;
}

.cellStuff {
  position: absolute;
  height: 1em;
  width: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}

.actionCardDropZone > div {
  color: #ffffff00;
  font-size: calc(var(--pixel) * 10);
}

.noWalkZone_swamp2 {
  height: calc(var(--pixel) * 18);
  width: calc(var(--pixel) * 21);
  background: url(./images/item-stone.png);
  background-size: contain;
  transform: translate(calc(-2 * var(--pixel)), calc(-1 * var(--pixel)));
}

.cellStuff span {
  font-size: 0.5em;
  opacity: 0.5;
}

.overlayCellPath {
  pointer-events: none;
  background: rgb(255, 221, 43);
  height: 1em;
  width: 1em;
  position: absolute;
  z-index: 1;
  opacity: 0.4;
}

/* Action Menu */

.actionMenu {
  width: calc(var(--pixel) * 32);
  position: absolute;
  background: #4f92bc;
  border: var(--pixel) solid white;
  box-shadow: 0 0 0 var(--pixel) black;
  padding: var(--pixel);
}

.actionMenu button {
  font-size: 1rem;
  background: none;
  border: 0;
  padding: 0;
  display: block;
  color: white;
  font-family: monospace;
  text-shadow: 1px 1px 0px black;
  font-size: 1rem;
  width: 100%;
}

.actionMenu button + button {
  margin-top: var(--pixel);
}

.actionMenu button:hover {
  background: #447898;
}
