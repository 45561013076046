.card {
  position: relative;
  border-radius: 0.5em;
  padding: 1em;
  height: 15em;
  width: 11em;
  box-sizing: border-box;
  /*border: .25rem solid rgb(255, 245, 234);*/
  color: #4d291d;
  text-align: center;
  float: left;
  background: rgb(242, 213, 173);
  background: linear-gradient(
    135deg,
    rgba(242, 213, 173, 1) 0%,
    rgba(203, 179, 144, 1) 100%
  );
}

.cardTier {
  opacity: 0.5;
  top: 0.5em;
  right: 0;
  left: 0;
  position: absolute;
  font-size: 0.75em;
}

.cardTitle {
  margin-top: 0.75em;
  font-weight: bold;
}

.cardDescription {
  margin-top: 0.25em;
  font-size: 0.825em;
}

.effectDescription {
  margin-top: 0.25em;
  font-size: 0.825em;
  font-style: italic;
  color: #2c554f;
}

.buttonPlayCard {
  position: absolute;
  bottom: 1em;
  left: 50%;
  right: 1em;
  display: block;
  width: 9em;
  margin-left: -4.5em;
  border: 0;
  padding: 0.5em 0.75em;
  background: #3f8a7e;
  box-shadow: 0 0.25em 0 #2c554f;
  color: white;
  font-weight: bold;
  letter-spacing: 0.1em;
  border-radius: 0.5em;
  cursor: pointer;
  font-size: 0.75em;
  text-transform: uppercase;
}
