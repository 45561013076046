.itemPiece {
  color: black;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  height: 1em;
  width: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  pointer-events: none;
  background-image: radial-gradient(rgba(255, 0, 0, 1), rgba(0, 0, 0, 0));
  background-size: contain;
}

.itemPiece span {
  font-size: 0.25em;
}

.itemPiece_action-card-tier-1 {
  background-image: url(item-actioncard.png);
}

.itemPiece_action-card-tier-2 {
  background-image: url(item-actioncard-tier-2.png);
}

.itemPiece_action-card-tier-3 {
  background-image: url(item-actioncard-tier-3.png);
}

.itemPiece_grenade {
  background-image: url(item-grenade-green.png);
  background-size: contain;
}

.itemPiece_super-grenade {
  background-image: url(item-grenade-red.png);
}

.itemPiece_stun-grenade {
  background-image: url(item-grenade-blue.png);
}

.itemPiece_medipack {
  background-image: url(item-medkit.png);
}

.itemPiece_medipack-small {
  background-image: url(item-potion.png);
}
